const Logo = () => (
  <svg viewBox="0 0 140 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M70 108V191" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M70 9V92" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M1 108V191" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M1 9V93" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M139 108V191" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M139 9V93" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M55 89L15 11" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M85 89L125 11" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M55 111L15 189" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M85 111L125 189" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M78 199H131" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M9 199H62" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M78.1 100H131.1" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M9 100H62" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M78.1 1H131.1" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M9 1H62" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
  </svg>
)

export default Logo
