const Number = ({ no }) => {
  switch (no) {
    case 0:
      return(
        <svg class="number" viewBox="0 0 72 198" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.1001 1H63.0001" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
          <path d="M1 7V90" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
          <path d="M16 87L56 9" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
          <path d="M71 7V91" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>

          <path d="M1 106V189" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>

          <path d="M71 106V189" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
          <path d="M9.1001 197H63.0001" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
        </svg>
      )
    case 1:
      return (
        <svg class="number" viewBox="0 0 72 198" fill="none" xmlns="http://www.w3.org/2000/svg">


          <path d="M16 87L56 9" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
          <path d="M71 7V91" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>


          <path d="M71 106V189" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>

        </svg>
      )
    case 2:
      return (
        <svg class="number" viewBox="0 0 72 198" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.1001 1H63.0001" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>


          <path d="M71 7V91" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
          <path d="M9.1001 98H63.0001" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
          <path d="M1 106V189" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>

          <path d="M9.1001 197H63.0001" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
        </svg>
      )
    case 3:
      return (
        <svg class="number" viewBox="0 0 72 198" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.1001 1H63.0001" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>


          <path d="M71 7V91" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
          <path d="M9.1001 98H63.0001" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>

          <path d="M71 106V189" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
          <path d="M9.1001 197H63.0001" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
        </svg>
      )
    case 4:
      return (
        <svg class="number" viewBox="0 0 72 198" fill="none" xmlns="http://www.w3.org/2000/svg">

          <path d="M16 87L56 9" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
          <path d="M71 7V91" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
          <path d="M9.1001 98H63.0001" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>


          <path d="M71 106V189" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>

        </svg>
      )
    case 5:
      return (
        <svg class="number" viewBox="0 0 72 198" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.1001 1H63.0001" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
          <path d="M1 7V90" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>


          <path d="M9.1001 98H63.0001" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>

          <path d="M71 106V189" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
          <path d="M9.1001 197H63.0001" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
        </svg>
      )
    case 6:
      return (
        <svg class="number" viewBox="0 0 72 198" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.1001 1H63.0001" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
          <path d="M1 7V90" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>


          <path d="M9.1001 98H63.0001" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
          <path d="M1 106V189" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>

          <path d="M71 106V189" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
          <path d="M9.1001 197H63.0001" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
        </svg>
      )
    case 7:
      return (
        <svg class="number" viewBox="0 0 72 198" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.1001 1H63.0001" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
          <path d="M1 7V90" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>

          <path d="M71 7V91" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>



          <path d="M71 106V189" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>

        </svg>
      )
    case 8:
      return (
        <svg class="number" viewBox="0 0 72 198" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.1001 1H63.0001" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
          <path d="M1 7V90" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>

          <path d="M71 7V91" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
          <path d="M9.1001 98H63.0001" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
          <path d="M1 106V189" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>

          <path d="M71 106V189" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
          <path d="M9.1001 197H63.0001" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
        </svg>
      )
    case 9:
      return (
        <svg class="number" viewBox="0 0 72 198" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.1001 1H63.0001" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
          <path d="M1 7V90" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>

          <path d="M71 7V91" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
          <path d="M9.1001 98H63.0001" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>

          
          <path d="M71 106V189" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
          <path d="M9.1001 197H63.0001" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
        </svg>
      )
    default:
      return (
        <svg class="number" viewBox="0 0 72 198" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.1001 1H63.0001" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
          <path d="M1 7V90" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
          <path d="M16 87L56 9" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
          <path d="M71 7V91" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
          <path d="M9.1001 98H63.0001" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
          <path d="M1 106V189" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
          <path d="M16 109L56 187" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
          <path d="M71 106V189" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
          <path d="M9.1001 197H63.0001" stroke="black" stroke-width="2" stroke-miterlimit="10"></path>
        </svg>
      )
  }
}

export default Number
