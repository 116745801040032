import Number from './Number'

const MultiNumber = ({ num, unit }) => {
  let digits = num.toString().split('')
  return(
    <div className="multi-number">
      {digits.map(d => <Number no={parseInt(d)}/>)}
      <div>
        {unit}
      </div>
    </div>
  )
}

export default MultiNumber
